import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Table,
  callbacksTableStorePack,
  settingsActions,
  utils,
  profileStorePack,
  pluginConfigs,
  mainHubActions,
  controls,
} from '@verdaccio/crminfo';

import { PAGES, hasAcces } from './../utils/userAccess';

class CallbacksPage extends Component {
  componentWillMount() {
    const { profile, history } = this.props;

    if (!hasAcces(PAGES.CALLBACKS, profile.roles)) {
      history.replace('/tickets');
    }
  }

  render() {
    const { setSorting, updateStatus } = this.props;

    const tableConfig = {
      fields: [
        {
          name: 'Created at',
          formatter: item => utils.formatTime(item.created_at, 'dd'),
          sortingConfig: {
            name: 'created_at',
            onChange: isAsc => setSorting('created_at', isAsc),
          },
        },
        {
          name: 'Event owner',
          formatter: (item) => {
            if (item.who && item.who !== 'system') {
              return <a onClick={() => this.props.pluginSetConfig(pluginConfigs.ClientPlugin({ clientId: item.event_owner }))} target="_blank">{item.who}</a>
            } else if (item.who) {
              return <span>{item.who}</span>
            } else {
              return 'Not specified'
            }
          },
        },
        {
          name: 'Comments',
          formatter: (item) => item.comment.split(', ').map(item => {
            if (item.indexOf('ref:') >= 0) {
              const re = /\[(.*)\]/;
              const match = item.match(re)[1];
              return <div key={match}> ref: [<a href={match} target="_blank">{match}</a>]</div>
            }
            return <div key={item}>{item}</div>
          }),
        },
        {
          name: 'Status',
          formatter: (item) => {
            const callbackRow = { id: item.id, status: item.status ? 0 : 1 };
            return (
              item.status
                ? <controls.Button onClick={() => updateStatus(callbackRow)}>seen</controls.Button>
                : <controls.Button onClick={() => updateStatus(callbackRow)}>unseen</controls.Button>
            )
          }
        },
      ]
    };

    return (
      <Table {...this.props} tableConfig={tableConfig} sticky stickyThreshold="58px" mainLoader />
    )
  }
}

const mapStateToProps = state => {
  const profileEntity = state[profileStorePack.name];

  return {
    loading: state.callbacks && state.callbacks.loading,
    loadingNext: state.callbacks && state.callbacks.loadingNext,
    list: (state.callbacks && state.callbacks.data && state.callbacks.data.list) || [],
    total: state.callbacks && state.callbacks.data && state.callbacks.data.total,
    page: state.callbacks && state.callbacks.data && state.callbacks.data.page,
    error: state.callbacks && state.callbacks.error,
    filter: state.callbacks && state.callbacks.filter,
    sorting: state.callbacks && state.callbacks.sorting,
    profile: profileEntity && profileEntity.data,
    profileLoading: profileEntity && profileEntity.loading,
  }
};

const mapDispatchToProps = dispatch => ({
  load: () => dispatch(callbacksTableStorePack.getTableAction()),
  loadNext: () => dispatch(callbacksTableStorePack.getTableNextAction()),
  updateRow: ticketRow => dispatch(callbacksTableStorePack.updateTableAction(ticketRow)),
  updateStatus: (callbackRow) => {
    dispatch(callbacksTableStorePack.updateTableAction(callbackRow))
  },
  pluginSetConfig: config => dispatch(mainHubActions.addNewConfig(config)),
  getSettings: () => dispatch(settingsActions.getSettingIfNeeded()),
  setSorting: (field, isAsc) => {
    dispatch(callbacksTableStorePack.setTableSortingAction(isAsc === null ? null : field, isAsc));
  },
  setFilter: (field, value) => {
    dispatch(callbacksTableStorePack.setTableFilterAction(field, value));
  },
  onResetFilters: () => {
    dispatch(callbacksTableStorePack.resetTableFilterAction());
    dispatch(callbacksTableStorePack.getTableAction());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CallbacksPage);
