import React, { Component } from 'react';
import { connect } from 'react-redux';
import FinanceNav from './../components/FinanceNav';
import classnames from 'classnames';
import moment from 'moment-timezone';

import { isOnlyQaffOrCsr } from './../utils/roles';

import {
  Table,
  financesTableStorePack,
  financeOperatorListTableStorePack,
  settingsActions,
  // utils,
  pluginConfigs,
  mainHubActions,
  profileStorePack,
  controls,
} from '@verdaccio/crminfo';

import styles from './finances.styl';
import stylesWriter from './writer-page.styl';
import { PAGES, hasAcces } from './../utils/userAccess';

const TYPES = [
  ['Fine', 'Fine'],
  ['Bonus', 'Bonus']
];

const USERS = [
  ['A', 'N'],
  ['M', 'Y']
];

const STATUS = [
  ['completed', 'completed'],
  ['rejected', 'rejected'],
  ['pending', 'pending']
];

class FinancesPage extends Component {
  componentWillMount() {
    const { profile, history } = this.props;

    if (!hasAcces(PAGES.FINANCES, profile.roles)) {
      history.replace('/tickets');
    }
  }

  componentDidMount() {
    const { loadOperators } = this.props;
    loadOperators();
}

  render() {
    const { setSorting, profile, setFilter } = this.props;
    const isQaffOrCsr = isOnlyQaffOrCsr(profile);

    const tableConfig = {
      fields: [
        {
          name: 'Created at',
          formatter: item => moment(item.created_at).format('MM/DD/YY HH:mm A'),
          dateRangeFilterConfig: {
            valueFrom: this.props.filter.created_at_gte,
            valueTo: this.props.filter.created_at_lte,
            onChange: (valueFrom, valueTo) => {
              this.props.setFilter('created_at_gte', valueFrom)
              this.props.setFilter('created_at_lte', valueTo)
            }
          },
          sortingConfig: {
            name: 'created_at',
            onChange: isAsc => setSorting('created_at', isAsc),
          },
          width: 1.2,
        },
        {
          name: 'Type',
          formatter: item => item.type,
          filterConfig: {
            value: this.props.filter.type,
            options: TYPES,
            onChange: value => setFilter('type', value),
          },
          width: .8,
          classnames: ['centered'],
        },
        {
          name: 'Title',
          formatter: item =>
            <span className={stylesWriter.tooltip} >
              <span className={stylesWriter.tooltip_trigger}>
                <span className={classnames(stylesWriter.order_ellipsis, stylesWriter.ellipsis_xxw)}>{item.comment}</span>
              </span>
              <span className={classnames(stylesWriter.tooltip_content, stylesWriter.tooltip_title_finances)}>
                {item.comment}
              </span>
            </span>,
          width: 2,
        },
        {
          name: 'Amount',
          formatter: item => {
            if (item.type === 'Fine') {
              return `($${(item.amount / 100).toFixed(2)})`
            }
            return `$${(item.amount / 100).toFixed(2)}`
          },
          sortingConfig: {
            name: 'amount',
            onChange: isAsc => setSorting('amount', isAsc),
          },
          width: 1,
          classnames: ['centered'],
        },
        {
          name: 'Order ID',
          formatter: item => item.order_info && (item.order_info.number || ''),
          onClick: item => this.props.pluginSetConfig(
            pluginConfigs.OrderPlugin({ orderId: item.order_info._id })
          ),
        },
        {
          name: 'Writer ID',
          formatter: item => item.owner_info.sg_id,
          onClick: item => {
            if (isQaffOrCsr) {
              return false;
            }
            this.props.pluginSetConfig(
              pluginConfigs.WriterPlugin({ writerId: item.owner_info._id })
            );
          },
          classnames: ['centered'],
        },
        // {
        //   name: 'Writer ID',
        //   formatter: item => {
        //     if (isQaffOrCsr) {
        //       return getHiddenWriterLabel(item.owner_info.sg_id);
        //     }
        //     return item.owner_info.name || item.owner_info.email;
        //   },
        //   onClick: item => {
        //     if (isQaffOrCsr) {
        //       return false;
        //     }
        //     this.props.pluginSetConfig(
        //       pluginConfigs.WriterPlugin({ writerId: item.owner_info._id })
        //     );
        //   },
        // },
        {
          name: 'Created by',
          formatter: item => item.executor,
          filterConfig: {
            value: this.props.filter.is_manual,
            options: USERS,
            onChange: value => setFilter('is_manual', value),
          },
          classnames: ['centered'],
        },
        {
          name: 'Approved by',
          formatter: item => item.confirm_by_info ? item.confirm_by_info.name || item.confirm_by_info.email : '',
          filterConfig: {
            value: this.props.filter.confirm_by,
            options: this.props.financeOperatorList,
            onChange: value => setFilter('confirm_by', value),
          },
        },
        {
          name: 'Status',
          formatter: item => item.status,
          filterConfig: {
            value: this.props.filter.status,
            options: STATUS,
            onChange: value => setFilter('status', value),
          },
          ddRight: true,
          classnames: ['centered'],
        },
      ]
    };

    return (
      <div>
        <div className={styles.filter}>
          <FinanceNav />
          <div className={classnames(styles.header_wrapper, styles.header_wrapper_right)}>
            <controls.ExportCSV filter={this.props.filter} sorting={this.props.sorting} dataType='isFinance' />
          </div>
        </div>
        <Table {...this.props} tableConfig={tableConfig} sticky stickyThreshold="159px" mainLoader />
      </div>
    )
  }
}

const mapStateToProps = state => {
  const profileEntity = state[profileStorePack.name];
  const profile = profileEntity && profileEntity.data;

  const financeOperatorList = (state.financeOperatorList.data.list || []).reduce((financeOperatorList, operator) => {
    if (operator && operator.id) {
      const operatorItem = (operator.name || '').trim().toLowerCase() || (operator.email || '').trim().toLowerCase() || 'No name';
      financeOperatorList.push([operatorItem, operator.id]);
    }
    return financeOperatorList;
  }, []);

  financeOperatorList.sort(function (a, b) {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  });

  return {
    loading: state.writersFinances && state.writersFinances.loading,
    loadingNext: state.writersFinances && state.writersFinances.loadingNext,
    list: (state.writersFinances && state.writersFinances.data && state.writersFinances.data.list) || [],
    total: state.writersFinances && state.writersFinances.data && state.writersFinances.data.total,
    page: state.writersFinances && state.writersFinances.data && state.writersFinances.data.page,
    error: state.writersFinances && state.writersFinances.error,
    filter: state.writersFinances && state.writersFinances.filter,
    sorting: state.writersFinances && state.writersFinances.sorting,
    profile,
    financeOperatorList,
  }
};

const mapDispatchToProps = dispatch => ({
  load: () => dispatch(financesTableStorePack.getTableAction()),
  loadNext: () => dispatch(financesTableStorePack.getTableNextAction()),
  loadOperators: () => dispatch(financeOperatorListTableStorePack.getTableAction()),
  pluginSetConfig: config => dispatch(mainHubActions.addNewConfig(config)),
  getSettings: () => dispatch(settingsActions.getSettingIfNeeded()),
  setSorting: (field, isAsc) => {
    dispatch(financesTableStorePack.setTableSortingAction(isAsc === null ? null : field, isAsc));
  },
  setFilter: (field, value) => {
    dispatch(financesTableStorePack.setTableFilterAction(field, value));
  },
  onResetFilters: () => {
    dispatch(financesTableStorePack.resetTableFilterAction());
    dispatch(financesTableStorePack.getTableAction());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FinancesPage);
