import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  utils,
  Table,
  pluginConfigs,
  ordersTableStorePack,
  settingsActions,
  mainHubActions,
  profileStorePack,
  controls,
  clientAutoTags,
} from '@verdaccio/crminfo';

import { Link } from 'react-router-dom';
import Icon from './../components/Icon';
import classnames from 'classnames';
import { isOnlyQaffOrCsr } from './../utils/roles';
import { getHiddenWriterLabel } from './../utils';
import OrderTagFilterDropdown from './../components/OrderTagFilterDropdown';

import styles from './writer-page.styl';
import stylesOrder from './orders-page.styl';
import gridStyles from './../styles/grid.styl';


const STATUSES = [
  ['Not offered', '-client_paid'],
  ['Declined', 'service_unavailable'],
  ['Offered', 'client_paid'],
  ['In work', 'writer_accept'],
  ['Completed', 'writer_completed'],
  ['Sent to client', 'force_review'],
  ['Accepted by client', 'client_accept'],
  ['Refund request', 'client_refund'],
  ['Refunded', 'refund_accept'],
  ['Refund reject', 'refund_decline'],
  ['Revision request', 'client_revision'],
  ['Revision', 'revision_accept'],
  ['Revision reject', 'revision_decline'],
  ['Revision completed', 'writer_revision_complete'],
  ['Archived', 'archive_order'],
  ['Deleted', 'delete_order'],
];

const DEFAULT_FILTER_STATUSES = [
  "writer_revision_complete",
  "revision_decline",
  "revision_accept",
  "client_revision",
  "client_accept",
  "force_review",
  "writer_completed",
  "writer_accept",
  "client_paid",
  "service_unavailable",
  "client_refund",
  "refund_decline",
];

class OrdersPage extends Component {
  constructor(props) {
    super(props);
    this.loadOrders = this.loadOrders.bind(this);

    const { defaultFilter } = this.props;

    this.state = {
      initialLoading: !defaultFilter ? true : null,
    }
  }

  componentDidMount() {
    const { defaultFilter } = this.props;
    this.props.getSettings();
    const orderId = this.props.match.params.orderId;
    if (orderId) {
      this.props.pluginSetConfig(pluginConfigs.OrderPlugin({ orderId, options: { closeUrl: '/orders' } }));
    }
    if (!defaultFilter) {
      this.setDefaultFilters();
    }
  }

  componentDidUpdate(prevProps) {
    const orderId = this.props.match.params.orderId;
    const prevOrderId = prevProps.match.params.orderId;
    if (orderId !== prevOrderId && orderId) {
      this.props.pluginSetConfig(pluginConfigs.OrderPlugin({ orderId, options: { closeUrl: '/orders' } }));
    }
    if (prevProps.defaultFilter && !this.props.defaultFilter) {
      this.setDefaultFilters();
    }
  }

  loadOrders() {
    const { loadOrders, defaultFilter } = this.props;
    
    if (!defaultFilter) {
      return;
    }

    loadOrders();
  }

  setDefaultFilters() {
    this.props.setFilter('main_status', DEFAULT_FILTER_STATUSES);
    this.props.setFilter('coming', true);
    this.props.setFilter('last_month', 6);
    this.props.loadOrders();

    setTimeout(() => {
      this.setState({
        initialLoading: null,
      })
    })
  }

  renderClient(item) {
    const autoTagList = [];
    const other = [];
    const client = item.client_info;
    const tags = client.tags;
    const clientName = client.name || client.email || client.phone || item.client_name || '';

    tags.map(item => (
      clientAutoTags.indexOf(item) >= 0 ? autoTagList.push(item) : other.push(item)
    ));

    const content = autoTagList.length ? autoTagList.map((item, idx) => <span key={idx} className={styles.tags}>{item}</span>) : null;
    const isCutName = clientName.length > 5;

    if (content) {
      return (
        <controls.Tooltip content={content} positionRight>
          <div className={styles.flex_row}><span className={styles.client_ellipsis}>{clientName}</span><b>(#)</b></div>
        </controls.Tooltip>
      );
    }

    return (
      <div className={classnames({ [styles.order_ellipsis]: isCutName })}>{clientName}</div>
    );
  }

  render() {
    const { filter, setFilter, setSorting, load, profile, orderSites, showRecommendation } = this.props;
    const isQaffOrCsr = isOnlyQaffOrCsr(profile);

    const tableConfig = {
      fields: [
        {
          name: 'Order number',
          formatter: item => {
            if (!!item.operator_comment) {
              return (
                <span className={styles.deadline_container}>
                  <span className={styles.tooltip}>
                    <span className={styles.tooltip_trigger}>
                      {this.props.match.params.orderId === item.id &&
                        <a onClick={() => this.props.pluginSetConfig(pluginConfigs.OrderPlugin({ orderId: item.id, options: { closeUrl: '/orders' } }))}>{item.number}</a>
                      }
                      {this.props.match.params.orderId !== item.id &&
                        <Link to={'/orders/' + item.id}>{item.number}</Link>
                      }
                      <Icon className="svg-icon comment-notice" iconName="comment-notice" />
                      <controls.NewWindowButton inTable className="icon_new_window" href={`/orders/${item.id}`} />
                    </span>
                    <span className={styles.tooltip_content}>
                      {item.operator_comment}
                    </span>
                  </span>
                </span>
              )
            }
            else {
              return this.props.match.params.orderId === item.id
                ? <div><a onClick={() => this.props.pluginSetConfig(pluginConfigs.OrderPlugin({ orderId: item.id, options: { closeUrl: '/orders' } }))}>{item.number}</a><controls.NewWindowButton inTable className="icon_new_window" href={`/orders/${item.id}`} /></div>
                : <div><Link to={'/orders/' + item.id}>{item.number}</Link><controls.NewWindowButton inTable href={`/orders/${item.id}`} /></div>
            }
          },
          sortingConfig: {
            name: 'client_paid',
            onChange: isAsc => this.props.setSorting('client_paid', isAsc),
            sortBy: 'by client paid,',
          },
          filterConfig: {
            value: this.props.filter.bysite,
            options: orderSites,
            onChange: value => setFilter('bysite', value),
            isMultiple: true,
          },
          width: 1.3,
        },
        {
          name: 'Title',
          formatter: item => item.title,
          width: 7,
        },
        {
          name: 'Status',
          formatter: item => {
            if (item.main_status === 'Offered') {
              const type = showRecommendation ? 'recommend' : 'suggest';
              return (
                <a
                  title={item.main_status}
                  onClick={() => this.props.pluginSetConfig(
                    pluginConfigs.WriterAllocationPlugin({
                      orderId: item.id,
                      type,
                    })
                  )}
                >
                  {item.main_status}
                </a>
              );
            } else {
              return item.main_status
            }
          },
          filterConfig: {
            value: this.props.filter.main_status,
            options: STATUSES,
            onChange: value => setFilter('main_status', value),
            isMultiple: true,
          },
          width: 1.1,
        },
        {
          name: 'Type',
          formatter: item => item.type,
          filterConfig: {
            value: this.props.filter.bytype,
            options: this.props.orderTypes,
            onChange: value => setFilter('bytype', value),
            isMultiple: true,
          },
          width: 1.4
        },
        {
          name: 'Subject',
          formatter: item => item.subject,
          width: 1.6,
          filterConfig: {
            value: this.props.filter.bysubject,
            options: this.props.orderSubjects,
            onChange: value => setFilter('bysubject', value),
            isMultiple: true,
            Other: true,
            showOther: true,
          },
        },
        {
          name: 'Size',
          width: .8,
          formatter: item => item.size,
          sortingConfig: {
            name: 'size',
            onChange: isAsc => setSorting('size', isAsc),
          },
        },
        {
          name: <span>Freelancer<br />price</span>,
          formatter: item => {
            return (
              <span className={styles.deadline_container}>
                {!!item.writer_price &&
                  utils.formatAmount(item.writer_price)
                }
                {!item.writer_price &&
                  utils.formatAmount(item.writer_price_dinamyc)
                }
              </span>
            )
          },
          width: .8,
        },
        {
          name: <span>Client<br />price</span>,
          formatter: item => utils.formatAmount(item.client_price, item.currency_code),
          sortingConfig: {
            name: 'price',
            onChange: isAsc => setSorting('price', isAsc),
          },
          width: .8,
        },
        {
          name: <span>Freelancer<br />deadline</span>,
          // formatter: item => utils.formatTime(item.writer_deadline, 'dt'),
          formatter: item => utils.formatTime(item.writer_deadline, 'dtnl'),
          sortingConfig: {
            name: 'writer_deadline',
            onChange: isAsc => setSorting('writer_deadline', isAsc),
          },
          width: .8,
        },
        {
          name: <span>Client<br />deadline</span>,
          // formatter: item => utils.formatTime(item.deadline, 'dt'),
          formatter: item => utils.formatTime(item.deadline, 'dtnl'),
          sortingConfig: {
            name: 'deadline',
            onChange: isAsc => this.props.setSorting('deadline', isAsc),
          },
          width: .8,
        },
        {
          name: 'Client',
          formatter: item => this.renderClient(item),
          onClick: item => this.props.pluginSetConfig(
            pluginConfigs.ClientPlugin({ clientId: item.client })
          ),
          width: .8,
        },
      ]
    };
    if (isQaffOrCsr) {
      tableConfig.fields.push(
        {
          name: 'Freelancer',
          width: 1.2,
          formatter: item => {
            if (item.writer) {
              return getHiddenWriterLabel(item.writer);
            }
            return item.writer_name;
          }
        },
      );
    } else {
      tableConfig.fields.push(
        {
          name: 'Freelancer',
          width: 1.2,
          onClick: item => !isQaffOrCsr && this.props.pluginSetConfig(
            pluginConfigs.WriterPlugin({ writerId: item.writer })
          ),
          formatter: item => {
            if (item.writer) {
              return <controls.WriterTags writerId={item.writer}>{item.writer_name}</controls.WriterTags>
            }
            return item.writer_name;
          }
        },
      );
    }
    return (
      <div>
        <div className={classnames(gridStyles.row, stylesOrder.filter, gridStyles.vertical)}>
          <div>
            <controls.Checkbox checked={filter.coming} onChange={() => { setFilter('coming', filter.coming ? '' : true); this.loadOrders(); }}>
              Hide missed DDL
            </controls.Checkbox>
          </div>
          <div className={classnames(gridStyles.row, gridStyles.vertical)}>
            <h4 className={styles.label_filter_order}>Tags:</h4>
            <OrderTagFilterDropdown />
          </div>
        </div>
        <Table {...this.props} loading={this.state.initialLoading || this.props.realLoading} load={this.loadOrders} tableConfig={tableConfig} sticky stickyThreshold="103px" mainLoader />
      </div>
    )
  }
}

const listToSelectMapper = (value) => ([value, value]);

const isNotSM = (roles) => {
  if (roles.indexOf('fcm') === -1) {
    return true;
  }
  return false;
};

const mapStateToProps = state => {


  const orderSubjects = (state.settings.orders_subjects || []).map(listToSelectMapper);
  const orderTypes = (state.settings.orders_types || []).map(listToSelectMapper);
  const profileEntity = state[profileStorePack.name];
  const orderSites = [];
  const sites = state.settings.sites || [];
  const profile = profileEntity && profileEntity.data;
  const isDeOperator = profile && profile.roles && (profile.roles.indexOf('csr_de') >= 0);
  const defaultFilter = state.orders.filter && state.orders.filter.main_status && state.orders.filter.main_status.length || null;

  sites.map(site => {
    if (site.name.length <= 0) return null;
    return orderSites.push([site.name, site._id])
  });

  const showRecommendation = profile.roles && isNotSM(profile.roles);

  return {
    isDeOperator,
    realLoading: state.orders.loading,
    loadingNext: state.orders.loadingNext,
    list: state.orders.data.list,
    total: state.orders.data.total,
    countList: state.orders.data.countList,
    page: state.orders.data.page,
    error: state.orders.error,
    filter: state.orders.filter,
    sorting: state.orders.sorting,
    settings: state.config,
    profile: profileEntity && profileEntity.data,
    orderSubjects: orderSubjects,
    orderTypes: orderTypes,
    orderSites,
    showRecommendation,
    defaultFilter,
  }
};

const mapDispatchToProps = dispatch => ({
  loadOrders: () => dispatch(ordersTableStorePack.getTableAction()),
  loadNext: () => dispatch(ordersTableStorePack.getTableNextAction()),
  pluginSetConfig: (config) => dispatch(mainHubActions.addNewConfig(config)),
  getSettings: () => dispatch(settingsActions.getSettingIfNeeded()),
  setSorting: (field, isAsc) => {
    dispatch(ordersTableStorePack.setTableSortingAction(isAsc === null ? null : field, isAsc));
  },
  setFilter: (field, value) => {
    dispatch(ordersTableStorePack.setTableFilterAction(field, value));
  },
  onResetFilters: () => {
    dispatch(ordersTableStorePack.resetTableFilterAction());
    setTimeout(() => {
      dispatch(ordersTableStorePack.getTableAction());
    }, 10);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersPage);
