export const isOnlyQaffOrCsr = (profile) => {
  if (!profile || !profile.roles) return false;
  // if (profile.roles.length === 1 && profile.roles.includes('qaff')) return true;
  // if (profile.roles.length === 1 && profile.roles.includes('csr')) return true;
  // if (profile.roles.length === 2 && profile.roles.includes('csr') && profile.roles.includes('qaff')) return true;
  // if (profile.roles.length > 2) {
  //   if (profile.roles.filter(r => r.indexOf('csr') < 0 && r.indexOf('qaff') < 0).length > 0) {
  //     return false;
  //   }
  // }
  // return false;

  if (profile.roles.filter(r => r.indexOf('qaff') < 0 && (r.indexOf('csr') < 0)).length) {
    return false;
  }
  return true;
}